import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CmsEntities } from 'client/data/models/cms';
import { SCROLL_OFFSET, SCROLL_DURATION } from 'site-modules/shared/constants/sub-navigation';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { Link } from 'site-modules/shared/components/link/link';
import { ArrowLink } from 'site-modules/shared/components/arrow-link/arrow-link';

import './articles-list.scss';

function renderList(list) {
  return (
    <ul className="list-unstyled">
      {list.map((entry, index) => {
        const url = entry.metadata('link-url').value();
        const title = entry.metadata('title').value();
        const date = entry.metadata('date').value();
        const key = `${entry.id}-${index}`;

        return (
          <li className="mb-1" key={key}>
            <Link
              to={url}
              className="article-link d-block"
              data-tracking-id="inline_article_view_content"
              data-tracking-value={`${title} - right`}
            >
              <span className="control-text size-16 fw-bold d-block">{title}</span>
              <span className="size-12 text-gray-dark d-block">{date}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export function ArticlesList({ content, noTitle, noMoreLink, className }) {
  const { title } = content;
  const entries = content.children();
  const linksNumBeforeCollapse = content.metadata('linksNumBeforeCollapse').number() || 10;
  const linksBeforeCollapse = entries.slice(0, linksNumBeforeCollapse);
  const restLinks = entries.slice(linksNumBeforeCollapse);
  const seeMoreLinkText = content.metadata('moreLinkText').value();
  const seeMoreUrl = content.metadata('moreLink').value();

  return (
    <section className={classnames('articles-list', className)}>
      {!noTitle && <h2 className="heading-3 mb-1_5">{title}</h2>}
      {renderList(linksBeforeCollapse)}
      {!!restLinks.length && (
        <ContentCollapse
          textClosed="See more"
          textOpen="See less"
          justify="justify-content-start"
          btnContainerClasses="m-0 p-0"
          btnClasses="p-0 size-16 text-primary-darker more-info-btn"
          btnTextClasses="fw-normal"
          minHeight="0px"
          showBtnBelow
          isEclipseFade={false}
          resetParams={{
            offset: SCROLL_OFFSET,
            duration: SCROLL_DURATION,
            to: 'scrollTop-more-about',
          }}
        >
          {renderList(restLinks)}
          {!noMoreLink && (
            <ArrowLink to={seeMoreUrl} className="mb-1">
              {seeMoreLinkText}
            </ArrowLink>
          )}
        </ContentCollapse>
      )}
    </section>
  );
}

ArticlesList.propTypes = {
  content: CmsEntities.Content.isRequired,
  noTitle: PropTypes.bool,
  noMoreLink: PropTypes.bool,
  className: PropTypes.string,
};

ArticlesList.defaultProps = {
  noTitle: false,
  noMoreLink: false,
  className: null,
};
