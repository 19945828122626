import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { noop } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { VideoPreview } from 'site-modules/shared/components/video-preview/video-preview';
import { CarNewsBadge } from 'site-modules/editorial/components/car-news/car-news-badge/car-news-badge';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';

import './spotlight.scss';

export function Spotlight({ content, openVideoModal, isLazyLoadImages }) {
  const {
    title,
    videoTitle,
    videoDescriptionHtml,
    'youtube-videoid': videoId,
    videoAspectRatio,
  } = content.getAllMetadata();

  function onWatchBtnClick() {
    openVideoModal({
      creativeId: 'car_news_industry_article_index',
      videoId,
      videoTitle,
      videoAspectRatio,
    });
  }

  return (
    <Row className="spotlight rounded no-gutters">
      <Col xs={12} lg={7} className="pos-r">
        <AspectRatioContainer aspectRatio="16:9" className="left-rounded">
          <VideoPreview
            videoId={videoId}
            openVideoModal={openVideoModal}
            videoTitle={videoTitle}
            creativeId="car_news_industry_article_index"
            isLazyLoadImages={isLazyLoadImages}
            isStaticPos
            isAutoHeight
            isWhiteBtn
          />
        </AspectRatioContainer>
        <CarNewsBadge icon="star-full" text="Must Watch" />
      </Col>
      <Col xs={12} lg={5} className="d-flex flex-column justify-content-center align-items-center text-center p-3">
        <p className="size-12 text-uppercase fw-bold text-blue-40 mb-0_5">{title}</p>
        <h3 className="heading-2 mb-0_5">{videoTitle}</h3>
        <p className="size-16 text-gray-darker mb-2">{videoDescriptionHtml}</p>
        <Button onClick={onWatchBtnClick} color="blue-50" className="watch-btn text-capitalize size-16">
          Watch Video
        </Button>
      </Col>
    </Row>
  );
}

Spotlight.propTypes = {
  content: CmsEntities.Content,
  openVideoModal: PropTypes.func,
  isLazyLoadImages: PropTypes.bool,
};

Spotlight.defaultProps = {
  content: DEFAULT_CONTENT,
  openVideoModal: noop,
  isLazyLoadImages: false,
};
